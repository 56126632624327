import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router";

import NavBar from "../library/ui_custom/nav_bar";
import { LinkItemProps } from "../library/ui_custom/sidebar";
import { checkIsMobile } from "./hooks/use_is_mobile";

function Layout({
  linkItems,
  onLogout,
}: {
  linkItems: Array<LinkItemProps>;
  onLogout: () => void;
}) {
  const { isOpen: isSidebarOpen, onToggle: onSidebarToggle } = useDisclosure({
    // did not use isMobile custom hook because it is not rendered in realtime since it relies useEffect
    defaultIsOpen: !checkIsMobile(),
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <NavBar
      onSidebarToggle={onSidebarToggle}
      isSidebarOpen={isSidebarOpen}
      onLogout={onLogout}
      linkItems={linkItems}
    >
      <Box margin={"2em"} flex={1}>
        <Outlet />
      </Box>
    </NavBar>
  );
}
export default Layout;
