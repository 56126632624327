import {
  Box,
  Card,
  CardBody,
  CardFooter,
  HStack,
  IconButton,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { Delete, Done, Edit } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { ActionItemType } from "./action_item_table";
import { ActionItemFormValues } from "./create_action_item";

const StyledCardFooter = chakra(CardFooter, {
  baseStyle: {
    display: "flex",
    justifyContent: "flex-end",
    flexFlow: "row wrap",
    gap: "1em",
  },
});

const StyledCardBody = chakra(CardBody, {
  baseStyle: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    gap: "1em",
  },
});

export default function ActionItemMobileCard({
  actionItems,
  onDeleteClicked,
  refetch = () => {},
}: {
  actionItems?: ActionItemType[];
  onDeleteClicked: (id: number) => void;
  refetch?: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const actionItemId = searchParams.get("actionItem_id");

  const { mutateAsync } = useMutation(
    async (data: Partial<ActionItemFormValues> & { actionItemId: number }) => {
      const res = await axios.put(
        `/api/user_app/action_items/${actionItemId}/update`,
        {
          ...data,
          actionItemId: data.actionItemId,
        }
      );

      if (res.data.actionItemId) {
        toast({
          title: "Success: Great job completing this action item! 🎉",
          status: "success",
          isClosable: true,
        });
        setSearchParams({});
      } else {
        toast({
          title: res.data.errors.join(","),
          status: "error",
          isClosable: true,
        });
      }

      refetch();
    }
  );

  return (
    <>
      {actionItems?.map((individualActionItem) => {
        return (
          <Card
            key={individualActionItem.id}
            backgroundColor="core.apple"
            marginTop={"2em"}
            padding={"0em"}
          >
            <StyledCardBody>
              <HStack>
                <IconButton
                  size={"md"}
                  borderRadius={"3em"}
                  icon={<Done />}
                  variant={"outline"}
                  aria-label={`mark action item ${individualActionItem.description} complete`}
                  data-umami-event="mark-action-item-complete-btn"
                  onClick={() => {
                    mutateAsync({
                      isComplete: !individualActionItem.isComplete,
                      actionItemId: individualActionItem.id,
                    });
                  }}
                />
              </HStack>
              <Box display={"flex"} flex={1}>
                {individualActionItem.description}
              </Box>
            </StyledCardBody>
            <StyledCardFooter>
              <IconButton
                size={"md"}
                icon={<Edit />}
                aria-label={"View/edit action item"}
                data-umami-event="view-edit-action-item-btn"
                onClick={() => {
                  searchParams.set(
                    "action_item_id",
                    `${individualActionItem.id}`
                  );
                  setSearchParams(searchParams);
                }}
              />
              <IconButton
                size={"md"}
                background={"darkRed"}
                icon={<Delete />}
                aria-label={"Delete action item"}
                data-umami-event="delete-action-item-btn"
                onClick={() => {
                  onDeleteClicked(individualActionItem.id);
                }}
              />
            </StyledCardFooter>
          </Card>
        );
      })}
    </>
  );
}
